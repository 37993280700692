// Get Zurb Foundation from the `vendor` folder
// If you need more foundation modules just add them to the @import statements
@charset "UTF-8";

$breakpoint-classes: (small medium large) !default;
$grid-column-gutter: (
  small: 20px,
  medium: 60px,
);

@import '../../node_modules/foundation-sites/_vendor/normalize-scss/sass/normalize';
@import "../../node_modules/foundation-sites/scss/util/util";
@import "../../node_modules/foundation-sites/scss/global";
@import "../../node_modules/foundation-sites/scss/grid/grid";
@import "../../node_modules/foundation-sites/scss/components/visibility";
@import '../../node_modules/foundation-sites/scss/components/float';

/*

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
) !default;

$global-width: rem-calc(1200) !default;

$breakpoint-classes: (small medium large) !default;

.foundation-mq {
    font-family: "#{-zf-bp-serialize($breakpoints)}";
}

*/



@include foundation-global-styles;
@include foundation-grid;
@include foundation-visibility-classes;
@include foundation-float-classes;

@charset "UTF-8";
/*

Icons
====

*/
/*

## Iconmoon


```
<div class="dummy-justfiy">
<div class="dummy-block dummy-block--with-content dummy-block--icon">Close<span class="icon icon--close"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Arrow-Down<span class="icon icon--arrow-down"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Arrow-Left<span class="icon icon--arrow-left"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Arrow-Right<span class="icon icon--arrow-right"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Arrow-Top<span class="icon icon--arrow-top"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Calendar<span class="icon icon--calendar"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Clock<span class="icon icon--clock"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">External Link<span class="icon icon--external-link"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Home<span class="icon icon--home"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Lupe<span class="icon icon--loupe"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Mail<span class="icon icon--mail"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Telefone<span class="icon icon--phone"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Pin<span class="icon icon--pin"></span></div>
<div class="dummy-block dummy-block--with-content dummy-block--icon">Download<span class="icon icon--download"></span></div>
</div>
```

*/
.icon {
    font-family: "icomoon";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    // Better Font Rendering ===========
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before {
        //fix transformable (example rotate)
        display: inline-block;
        //animate cool
        transition: all 300ms ease-in-out;
    }
}

@mixin icon {
    //auf before selbst legen
    font-family: "icomoon";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    // Better Font Rendering ===========
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //fix transformable (example rotate)
    display: inline-block;
}

.icon-th:before {
  content: "\f00a";
}
.icon-check:before {
  content: "\f00c";
}
.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-times:before {
  content: "\f00d";
}
.icon-search-plus:before {
  content: "\f00e";
}
.icon-search-minus:before {
  content: "\f010";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-twitter-square:before {
  content: "\f081";
}
.icon-facebook-square:before {
  content: "\f082";
}
.icon-linkedin-square:before {
  content: "\f08c";
}
.icon-thumb-tack:before {
  content: "\f08d";
}
.icon-arrows-alt:before {
  content: "\f0b2";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-navicon:before {
  content: "\f0c9";
}
.icon-reorder:before {
  content: "\f0c9";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-youtube-square:before {
  content: "\f166";
}
.icon-xing-square:before {
  content: "\f169";
}
